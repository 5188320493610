<template>
  <div class="exchangePage flex-column-center">
    <div class="exchangeBg">
      <img
        class="exchangeBanner"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/exchangeBanner.png"
        alt=""
      >
      <div class="exchangeBox">
        <div
          class="exchangeItemBox flex-column-center"
          v-for="(item,index) in list "
          :key="index"
        >
          <div class="imgBg flex-column-center">
            <img
              class="itemImg"
              :src="item.img"
              alt=""
            >
            <div class="itemName">{{item.name}}</div>
          </div>
          <div class="coinLine flex-row-center">
            <img
              class="coinIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/taskRewardIcon.png"
              alt=""
            >
            {{item.price}}
          </div>
          <img
            class="exchangeBtn"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/excBtn.png"
            alt=""
            @click="goExchangeItem(item)"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import { Toast } from 'vant'
import { getExchangeList, exchangeItem } from '../../api/service'
export default {
  name: 'Exchange',
  data() {
    return {
      list: []
    }
  },
  created() {
    this.initList()
  },
  methods: {
    initList() {
      getExchangeList().then((res) => {
        if (res.code == 0) {
          this.list = res.data.list
        }
      })
    },
    goExchangeItem(item) {
      exchangeItem(item.id).then((res) => {
        if (res.code == 0) {
          Toast('兌換成功')
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Exchange.scss';
</style>